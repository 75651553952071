<template>
  <a href="https://t.me/iknow365" target="_blank" class="chat-support">
    <IconComponent name="Telegram" />
  </a>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "ChatSupportComponent",
  components: { IconComponent },
};
</script>

<style lang="stylus">
.chat-support {
  background: #08c;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 60px;
  right: 60px;
  box-shadow: 2px 2px 20px 7px rgba(34, 60, 80, 0.2)
  cursor pointer

  & svg {
    width 25px
  }
}
</style>
